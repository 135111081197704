import { useMemo } from 'react';

import { HowToHelpType } from '@shared/enums';

import { useIntegrationConfigs } from './useIntegrationConfigs';

export const useShops = (type?: HowToHelpType) => {
  const integrationConfigs = useIntegrationConfigs();

  if (!type) {
    return useMemo(() => integrationConfigs.flatMap((config) => config.shops), [type, integrationConfigs]);
  }

  return useMemo(
    () => integrationConfigs.find((config) => config.type === type)?.shops || [],
    [type, integrationConfigs],
  );
};
