import { IconCheckboxForm } from '@shared/icons/IconCheckboxForm';
import clsx from 'clsx';
import React from 'react';

interface CheckboxFieldProps {
  id?: string;
  name: string;
  label?: string | React.ReactElement;
  className?: string;
  isChecked: boolean;
  onChange?: (e: any) => void;
}

export const CheckboxNotFormField = (props: CheckboxFieldProps) => {
  const { name, id = name, label, className = '', isChecked = false, onChange = () => {} } = props;

  return (
    <div className={clsx('relative', className)}>
      <input id={id} name={name} type="checkbox" className="hidden" checked={isChecked} onChange={onChange} />
      <IconCheckboxForm checked={isChecked} className="absolute left-0 top-0 pointer-events-none" />
      {label && (
        <label htmlFor={id} className="block py-1 pl-10 text-xxs cursor-pointer">
          {label}
        </label>
      )}
    </div>
  );
};
