import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '@hooks/useLanguage';
import { transliterate } from '@shared/utils/strings';

import { GeoLocality } from '@store/api/types';

export function useGetPrettyLocalityName() {
  const { t } = useTranslation();
  const language = useLanguage();

  const localityCategoryAbbr = useMemo(
    () => ({
      K: t('city-abbr'),
      M: t('city-abbr'),
      T: t('town-abbr'),
      C: t('village-abbr'),
      X: t('village-abbr'),
    }),
    [t],
  );

  return useCallback(
    (locality: string, category: GeoLocality['category'], district?: string) => {
      const transliteratedValue = transliterate(locality, language);

      return `${category ? `${localityCategoryAbbr[category]}. ` : ''}${transliteratedValue}${
        district ? ` (${transliterate(district, language)} ${t('district')})` : ''
      }`;
    },
    [language, t],
  );
}
